import { useState } from 'react';
import MonitorSection from './MonitorSection';
import RemovalSection from './RemovalSection';
import AboutSection from './AboutSection';
import CaseStudiesSection from './CaseStudiesSection';
// import ResourcesSection from './ResourcesSection';

const MENU_SECTIONS = [
  {
    id: 'monitor',
    title: 'Monitor',
    component: MonitorSection
  },
  {
    id: 'removal',
    title: 'Removal',
    component: RemovalSection
  },
  {
    id: 'about',
    title: 'About',
    component: AboutSection
  },
  {
    id: 'case-studies',
    title: 'Case Studies',
    component: CaseStudiesSection
  },
  // {
  //   id: 'resources',
  //   title: 'Resources',
  //   component: ResourcesSection
  // }
];

const RIGHT_ARROW_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 19 7-7-7-7"/>
  </svg>
);

export default function MobileMenu() {
  const [activeSection, setActiveSection] = useState(null);
  
  const CurrentSectionComponent = activeSection 
    ? MENU_SECTIONS.find(section => section.id === activeSection)?.component 
    : null;

  return (
    <div className="mobile-menu">
      <nav>
        {!activeSection ? (
          // Main Menu
          <>
            {MENU_SECTIONS.map((section) => (
              <button
                key={section.id}
                onClick={() => setActiveSection(section.id)}
                className="menu-button"
              >
                {section.title}
                {RIGHT_ARROW_ICON}
              </button>
            ))}
            <a href="/pricing" className="menu-button">
              Pricing
              {RIGHT_ARROW_ICON}
            </a>
          </>
        ) : (
          // Section Page
          <>
            <button
              onClick={() => setActiveSection(null)}
              className="back-button"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m11 1-7 7 7 7"/>
              </svg>
              Back
            </button>
            {CurrentSectionComponent && <CurrentSectionComponent />}
          </>
        )}
        <div className="mobile-auth">
          <a className="login" href="https://app.contentremoval.com/login" target="_self">Sign In</a>
          <a className="signup" href="/get-started" target="_self">Get Started</a>
        </div>
      </nav>
    </div>
  );
} 